<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-24 16:53:25
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-27 10:47:11
 * @FilePath: \mid-autumn\src\views\home\jinchang\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <img v-if="show == false" style="width: 100%; height: auto" src="../../../assets/中秋进场_10.gif" alt="" />
    <!-- <img
      v-else
      style="width: 100%; height: auto"
      src="../../../assets/9fc3cde98225df52592f346ab687cfc.jpg"
      alt=""
      @click="clickJump"
    /> -->
    <!-- <div class="wenzi">
      <transition name="fade">
        <!-- <p v-if="show">100</p> -->
    <!-- <span v-if="wenziCount == 0 || wenziCount == 1 || wenziCount == 2 || wenziCount == 3 || wenziCount == 4">
          欢迎来到元隆宇宙
        </span>
      </transition>

      <br />
      <span v-if="wenziCount == 1 || wenziCount == 2 || wenziCount == 3 || wenziCount == 4">
        四周弥漫着浓厚的艺术气息
      </span>
      <br />
      <span v-if="wenziCount == 2 || wenziCount == 3 || wenziCount == 4">让我们在美术馆内部仔细探索</span>
      <br />
      <span v-if="wenziCount == 3 || wenziCount == 4">寻找到那些最能给人带来灵感的藏品</span>
      <br />
      <span v-if="wenziCount == 4">解锁隐藏惊喜吧！</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'MidAutumnIndex',
  data() {
    return {
      play: true,
      show: false,
      wenziCount: -1,
    };
  },

  mounted() {
    setTimeout(() => {
      this.show = true;
      this.$router.push('/home/first');
      // this.wenziCount = 0;
      // setInterval(() => {
      //   if (this.wenziCount < 4) {
      //     this.wenziCount += 1;
      //   } else {
      //     this.wenziCount = 4;
      //   }
      // }, 1000);
    }, 4000);
    // setTimeout(() => {
    //   this.$router.push('/home/first');
    // }, 5500);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  background: none;
  position: relative;
  overflow: hidden;
  /* background: url('../../../assets/中秋进场_2.gif');
  background-size: 100% auto;
  background-position: center center; */
  .wenzi {
    width: 90%;
    height: auto;
    font-weight: bold;
    position: absolute;
    top: 2%;
    text-align: center;
    color: #fff;
    margin-left: 5%;
    font-size: 17px;
  }
  .video {
    width: 100%;
    height: auto;
    object-fit: fill;
  }
}
</style>
