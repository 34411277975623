<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-25 22:53:27
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-27 14:43:52
 * @FilePath: \mid-autumn\src\views\home\xia\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <dl>
      <dt>
        <img src="../../../assets/qiu.png" alt="" />
      </dt>
      <dd>Judy提着裙摆轻快的穿梭在芦苇丛里，一阵微风吹过，Judy回过头，温柔地笑了，彷佛是秋之女神的降临。</dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'MidAutumnIndex',

  data() {
    return {};
  },

  mounted() {
    setTimeout(() => {
      this.$router.push('/home/popup');
    }, 5000);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000;
  color: #fff;
  dl {
    dt {
      width: 50%;
      margin: 12% auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    dd {
      width: 80%;
      height: auto;
      line-height: 20px;
      text-align: center;
      position: absolute;
      bottom: 7%;
    }
  }
}
</style>
