/*
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-17 13:31:41
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-17 14:59:16
 * @FilePath: \mid-autumn\src\views\home\end\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE/
 */
import request from '../../../utils/request/request.js';
export function addPhone(data) {
  return request({
    url: '/mid_autumn_festival/savePhoneNumber',
    method: 'post',
    data,
  });
}
