<template>
  <div class="index">
    <van-popup v-model="show" class="popup">
      <div v-if="count == 1">
        <Popup />
      </div>
      <div v-else-if="count == 2">
        <PopupTwo />
      </div>
      <div v-else-if="count == 3">
        <PopupThree />
      </div>
      <div v-else>
        <PopupFour />
      </div>
      <div class="continue">
        <van-button v-if="this.count < 4" class="button" type="primary" block @click="jumpSened()">继续探索</van-button>
        <van-button v-else class="button" type="primary" block @click="next()">下一步</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
import Popup from '../../../components/popup';
import PopupTwo from '../../../components/popupTwo';
import PopupThree from '../../../components/popupThree';
import PopupFour from '../../../components/popupFour';
export default {
  name: 'MidAutumnIndex',

  data() {
    return {
      show: true,
      count: 0,
      clientHeight: document.body.clientHeight,
    };
  },
  components: { Popup, PopupTwo, PopupThree, PopupFour },
  created() {
    if (this.$router.history.current.path == '/home/popup') {
      if (localStorage.getItem('count')) {
        if (localStorage.getItem('count') == 1) {
          localStorage.removeItem('count');
          this.count = 2;
          localStorage.setItem('count', this.count);
        } else if (localStorage.getItem('count') == 2) {
          localStorage.removeItem('count');
          this.count = 3;
          localStorage.setItem('count', this.count);
        } else {
          localStorage.removeItem('count');
          this.count = 4;
          localStorage.setItem('count', this.count);
        }
        console.log(localStorage.getItem('count'), '1111');
      } else {
        this.count = 1;
        localStorage.setItem('count', this.count);
        console.log(localStorage.getItem('count'), '21222');
      }
    }
  },
  mounted() {},
  methods: {
    jumpSened() {
      // this.$router.push(`/home/first/${this.count}`);
      this.$router.push({
        name: 'first',
        params: {
          count: this.count,
        },
      });
      // this.$router.push({
      //   path: '/home/first',
      //   query: {
      //     count: this.count,
      //   },
      // });
    },
    next() {
      this.$router.push('/home/end');
      localStorage.removeItem('count');
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: none;
  .popup {
    width: 100%;
    height: 100%;
    background: url('../../../assets/中秋0816/遮照.png');
    background-size: 100% 100%;
    background-position: center center;
    .size {
      margin: 0 auto;
      width: 90%;
      height: 290px;
      background: url('../../../assets/中秋0816/beijing-框.png');
      background-size: 100% 100%;
      background-position: center center;
      text-align: center;
      margin-top: 60%;
      letter-spacing: 5px;

      .yellow {
        padding-top: 26%;
        color: #fff;
        font-size: 23px;
        font-weight: bold;
        line-height: 30px;

        span {
          display: inline-block;
          margin-top: 40px;
          font-size: 16px;
          color: #66ffff;
          letter-spacing: 4px;
        }
      }
    }

    .sure {
      font-size: 16px;
      width: 37%;
      height: 70px;
      background-image: url('../../../assets/中秋0816/进度框.png');
      background-size: 100% 100%;
      background-position: center center;
      position: absolute;
      top: 5%;
      right: 5%;

      .button {
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        letter-spacing: 10px;
      }
    }

    .continue {
      width: 250px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: 50px;
      position: absolute;
      bottom: 12%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50px;
      background-image: url('../../../assets/中秋0816/按钮 框.png');
      background-size: 100% 100%;
      background-position: center center;

      .button {
        height: 100%;
        /* margin-top: 10px; */
        border-radius: 50px;
        background: none;
        font-size: 21px;
        border: none;
      }
    }
  }
}

/* .index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url('../../../assets/中秋0816/遮照.png');
  background-size: 100% 100%;
  background-position: center center;
  .size {
    margin: 0 auto;
    width: 90%;
    height: 290px;
    background: url('../../../assets/中秋0816/beijing-框.png');
    background-size: 100% 100%;
    background-position: center center;
    text-align: center;
    margin-top: 200px;
    letter-spacing: 5px;

    .yellow {
      padding-top: 26%;
      color: #fff;
      font-size: 23px;
      font-weight: bold;
      line-height: 30px;
      span {
        display: inline-block;
        margin-top: 40px;
        font-size: 16px;
        color: #66ffff;
        letter-spacing: 4px;
      }
    }
  }
  .sure {
    font-size: 16px;
    width: 37%;
    height: 70px;
    background-image: url('../../../assets/中秋0816/进度框.png');
    background-size: 100% 100%;
    background-position: center center;
    position: absolute;
    top: 5%;
    right: 5%;
    .button {
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      letter-spacing: 10px;
    }
  }
  .continue {
    width: 250px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 50px;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px;
    background-image: url('../../../assets/中秋0816/按钮 框.png');
    background-size: 100% 100%;
    background-position: center center;
  }
} */
</style>
