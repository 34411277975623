<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-22 10:03:52
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-24 21:25:12
 * @FilePath: \mid-autumn\src\views\home\popupTwo\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <div class="sure">
      <van-button type="primary" class="button" @click="jumpSened()">当前进度(2/4)</van-button>
    </div>
    <div class="size">
      <p class="yellow">
        你在探索中获得了
        <br />
        RED精华
        <br />
        <span>再接再厉吧</span>
      </p>
    </div>
    <div class="continue">
      <van-button
        class="buttonTwo"
        style="height: 100%; border-radius: 50px; background: none; font-size: 21px; border: none"
        type="primary"
        block
        @click="$router.push('/home/first')"
      >
        继续探索
      </van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MidAutumnIndex',

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background: url('../../assets/中秋0816/遮照.png');
  background-size: 100% 100%;
  background-position: center center; */
  .size {
    margin: 0 auto;
    width: 90%;
    height: 290px;
    background: url('../../assets/中秋0816/beijing-框.png');
    background-size: 100% 100%;
    background-position: center center;
    text-align: center;
    margin-top: 200px;
    letter-spacing: 5px;

    .yellow {
      padding-top: 26%;
      color: #fff;
      font-size: 23px;
      font-weight: bold;
      line-height: 30px;
      span {
        display: inline-block;
        margin-top: 40px;
        font-size: 16px;
        color: #66ffff;
        letter-spacing: 4px;
      }
    }
  }
  .sure {
    font-size: 16px;
    width: 37%;
    height: 70px;
    background-image: url('../../assets/中秋0816/进度框.png');
    background-size: 100% 100%;
    background-position: center center;
    position: absolute;
    top: 5%;
    right: 5%;
    .button {
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      letter-spacing: 10px;
    }
  }
  .continue {
    width: 250px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 50px;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px;
    background-image: url('../../assets/中秋0816/按钮 框.png');
    background-size: 100% 100%;
    background-position: center center;
  }
}
</style>
