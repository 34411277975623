<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-24 17:11:29
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-27 11:56:50
 * @FilePath: \mid-autumn\src\views\home\zuozuotu\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <div class="zuozuotu">
      <!-- <img class="image" src="../../../assets/作作兔.gif" alt="" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'MidAutumnIndex',

  data() {
    return {};
  },

  mounted() {
    setTimeout(() => {
      this.$router.push('/home/popup');
    }, 13200);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000;
  .zuozuotu {
    width: 90%;
    height: 90%;
    margin: 5%;
    margin-top: 10%;
    background-image: url('../../../assets/嫦小月 EP1 FINAL v4_0711竖版 00_00_10-00_00_25 00_00_00-00_00_30.gif');
    background-size: 100% 100%;
    background-position: center center;
    /* image {
      width: 100%;
      height: 100%;
    } */
  }
}
</style>
