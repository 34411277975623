<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-15 09:40:18
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-27 13:18:29
 * @FilePath: \mid-autumn\src\views\home\index\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <!-- 中部 -->
    <van-row class="main">
      <van-row class="footer">
        <van-button class="button" type="primary" block @click="$router.push('/home/jinchang')"></van-button>
      </van-row>
    </van-row>
    <!-- 尾部 -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url('../../../assets/首页_4.gif');
  background-size: 100% auto;
  background-position: center center;

  .main {
    flex: 1;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    .UOVA {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      white-space: pre-wrap;
    }
    .footer {
      width: 160px;
      height: 208px;
      position: absolute;
      bottom: 20%;
      right: 0;
      .button {
        height: 100%;
        border-radius: 50px;
        background: none;
        font-size: 21px;
        border: none;
      }
    }
    .dianzheli {
      width: 100px;
      height: 100px;
      position: absolute;
      bottom: 10%;
      right: 0;
    }
  }
}
</style>
