<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-15 09:40:18
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-27 14:47:25
 * @FilePath: \mid-autumn\src\views\home\first\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <!-- 中部 -->
    <van-overlay :show="showone" @click="showClick()">
      <div class="wrapper" @click.stop>
        <div
          style="width: 200px; height: 200px; margin: 50% auto; text-align: center; color: #fff; padding-top: 20px"
          class="jiqi"
        >
          集齐四个宝石
          <br />
          <img style="width: 100px; height: auto; margin: 30px auto" src="../../../assets/图层 12.png" alt="" />
          <br />
          即可获得特殊数字藏品
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showThreeList" @click="showThreeList = false">
      <div class="wrapper" @click.stop>
        <div>
          <div
            style="
              display: flex;
              alige-item: center;
              flex-direction: column;
              width: auto;
              height: auto;
              background: none;
              margin: 40% auto;
              text-align: center;
              color: #fff;
            "
          >
            <!-- <div style="display: flex">
              <img style="width: 100px; height: 100px; margin: 11px auto" src="../../../assets/zuo.png" alt="" />
              <img style="width: 100px; height: 100px; margin: 11px auto" src="../../../assets/shang.png" alt="" />
            </div> -->

            <span style="line-height: 30px; margin-top: 80px">左右滑动，发现更多精彩</span>
            <span style="line-height: 30px">点击上下层按钮，观看更多藏品</span>
            <div style="color: #fff; text-align: center; color: #ccc; margin-top: 80px">点击页面空白位置继续</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-row class="main">
      <iframe
        v-if="countList == 0"
        src="https://720yun.com/t/65vk6eiw58e?scene_id=99882569"
        frameborder="no"
        width="100%"
        height="100%"
      ></iframe>
      <iframe
        v-else-if="countList == 1"
        src="https://720yun.com/t/65vk6eiw58e?scene_id=99879825"
        frameborder="no"
        width="100%"
        height="100%"
      ></iframe>
      <iframe
        v-else-if="countList == 2"
        src="https://720yun.com/t/65vk6eiw58e?scene_id=99879824"
        frameborder="no"
        width="100%"
        height="100%"
      ></iframe>
      <iframe
        v-else
        src="https://720yun.com/t/65vk6eiw58e?scene_id=99879823"
        frameborder="no"
        width="100%"
        height="100%"
      ></iframe>
    </van-row>
    <van-button type="primary" class="firstButton" @click="upper()">
      <img src="../../../assets/上.png" alt="" />
    </van-button>
    <van-button type="primary" class="nextButton" @click="nexter()">
      <img src="../../../assets/下.png" alt="" />
    </van-button>
    <van-button type="primary" class="baoshi">
      <img src="../../../assets/宝石.png" alt="" />
      <div>({{ count }}/4)</div>
    </van-button>
    <img v-if="imgflag" class="show" src="../../../assets/图层 7 拷贝.png" alt="" />
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  data() {
    return {
      countList: 0,
      showone: true,
      showTwoList: false,
      showThreeList: false,
      count: 0,
      imgflag: false,
    };
  },
  created() {
    if (this.$route.params.count) {
      this.showone = false;
      this.count = this.$route.params.count;
      this.imgflag = false;
    }
    if (this.showone == true) {
      setTimeout(() => {
        this.showone = false;
        this.imgflag = true;
        if (this.imgflag == true) {
          setTimeout(() => {
            this.imgflag = false;
          }, 3000);
        }
      }, 3000);
    }
  },
  methods: {
    showClick() {
      this.showone = false;
      this.imgflag = true;
      if (this.imgflag == true) {
        setTimeout(() => {
          this.imgflag = false;
        }, 3000);
      }
    },
    showtwoClick() {
      this.showTwoList = false;
      if (this.showTwoList == false) {
        this.showThreeList = true;
      }
    },
    upper() {
      if (this.countList < 3) {
        this.countList += 1;
      } else {
        Notify({ type: 'danger', message: '已经到最高层了，再上就要上天了' });
      }
    },
    nexter() {
      if (this.countList != 0 && this.countList) {
        this.countList -= 1;
      } else {
        Notify({ type: 'danger', message: '已经到第一层了' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .main {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow-y: scroll;
    position: relative;
  }
  .jiqi {
    width: 200px;
    height: 200px;
    margin: 40% auto;
    text-align: center;
    color: #fff;
    padding-top: 5px;
    background-image: url('../../../assets/矩形 2.png');
    background-size: 100% 100%;
    background-position: center center;
  }
  .show {
    width: 30%;
    height: auto;
    position: absolute;
    bottom: 20%;
    right: 35%;
  }
  .baoshi {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2%;
    left: 2%;
    font-size: 20px;
    background: none;
    border: none;
    color: #fff;
    z-index: 999;
    img {
      width: 56px;
      height: 56px;
    }
  }
  .firstButton {
    width: auto;
    height: auto;
    position: absolute;
    top: 40%;
    left: 2%;
    transform: translateY(-50%);
    background: none;
    border: none;
    z-index: 999;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .nextButton {
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
    background: none;
    border: none;
    z-index: 999;

    img {
      width: 40px;
      height: 40px;
    }
  }
  .seconedButton {
    width: 100px;
    height: 100px;
    // border-radius: 50%;
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    background: none;
    transform: translate(-50%, -50%);
    border: none;
    background-size: 100% 100%;
    background-position: center center;

    &.button1 {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.button3 {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.button2 {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .footer {
    width: 100%;
    height: 30%;
    background: none;
    position: relative;
    position: absolute;
    bottom: 0;

    .explore {
      width: 30%;
      height: 20%;
      margin: 12% auto;
      margin-left: 50%;
      transform: translateX(-50%);
      position: relative;

      img {
        width: 80%;
        height: 25%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .start-button {
      width: 60%;
      height: 20%;
      text-align: center;
      line-height: 60px;
      border-radius: 50px;
      position: absolute;
      bottom: 12%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50px;
      background-image: url('../../../assets/中秋0816/按钮 框.png');
      background-size: 100% 100%;
      background-position: center center;

      .button {
        height: 100%;
        border-radius: 50px;
        background: none;
        font-size: 20px;
        border: none;
        letter-spacing: 10px;
      }
    }
  }
  .popup {
    width: 100%;
    height: 100%;
    background: url('../../../assets/中秋0816/遮照.png');
    background-size: 100% 100%;
    background-position: center center;

    .size {
      margin: 0 auto;
      width: 90%;
      height: 290px;
      background: url('../../../assets/中秋0816/beijing-框.png');
      background-size: 100% 100%;
      background-position: center center;
      text-align: center;
      margin-top: 60%;
      letter-spacing: 5px;

      .yellow {
        padding-top: 26%;
        color: #fff;
        font-size: 23px;
        font-weight: bold;
        line-height: 30px;

        span {
          display: inline-block;
          margin-top: 40px;
          font-size: 16px;
          color: #66ffff;
          letter-spacing: 4px;
        }
      }
    }

    .sure {
      font-size: 16px;
      width: 37%;
      height: 70px;
      background-image: url('../../../assets/中秋0816/进度框.png');
      background-size: 100% 100%;
      background-position: center center;
      position: absolute;
      top: 5%;
      right: 5%;

      .button {
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        letter-spacing: 10px;
      }
    }

    .continue {
      width: 250px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: 50px;
      position: absolute;
      bottom: 12%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50px;
      background-image: url('../../../assets/中秋0816/按钮 框.png');
      background-size: 100% 100%;
      background-position: center center;

      .button {
        height: 100%;
        /* margin-top: 10px; */
        border-radius: 50px;
        background: none;
        font-size: 21px;
        border: none;
      }
    }
  }
}
#container {
  height: 400px;
}
</style>
