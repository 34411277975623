<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-15 10:18:01
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-27 13:48:00
 * @FilePath: \mid-autumn\src\views\home\end\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <!-- 中部 -->
    <div class="main">
      <img style="margin-left: 35%; width: 30%; height: auto" src="../../../assets/中秋0816/nft.jpg" alt="" />
      <div class="size">
        <p @failed="onFailed">
          恭喜您获得了
          <br />
          <span style="color: #009999">《花好月圆阖家欢》数字藏品</span>
          空投的权利,
          <br />
          请在下方输入您的手机号码,
          <br />
          以便后续进行藏品发放
          <van-form validate-first @failed="onFailed">
            <!-- rgb(88 79 79 / 68%) -->
            <van-field
              input-align="center"
              class="input"
              v-model="value"
              name="pattern"
              placeholder="|点击输入手机号"
              error-message-align="center"
              :rules="[{ pattern, message: '请输入格式正确的手机号', required: true }]"
            />
          </van-form>
        </p>
      </div>
    </div>
    <van-row class="footer">
      <van-button class="button" round block type="primary" native-type="submit" @click="submit(value)">
        确定
      </van-button>
    </van-row>
    <!-- 尾部 -->
  </div>
</template>

<script>
import { Notify } from 'vant';
import { addPhone } from './index';
export default {
  data() {
    return {
      value: '',
      pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
    };
  },
  methods: {
    async submit() {
      if (this.value && this.value.length == 11) {
        addPhone({ telNo: this.value }).then(res => {
          console.log(res, 'res');
          if (res.data.code == 200) {
            this.$router.push('/home/endList');
          } else {
            Notify({ type: 'danger', message: res.data.msg });
          }
        });
      } else {
        Notify({ type: 'danger', message: '请输入正确格式的手机号' });
      }
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../../../assets/星空.jpg');
  background-size: 100% 100%;
  background-position: center center;
  position: relative;
  .main {
    flex: 1;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    background-image: url('../../../assets/中秋0816/遮照.png');
    background-size: 100% 100%;
    background-position: center center;
    position: relative;
    img {
      width: 80%;
      margin-left: 10%;
      margin-top: 15%;
    }
    .size {
      margin-top: 10%;
      p {
        font-size: 15px;
        text-align: center;
        line-height: 25px;
        span {
          display: inline-block;
        }
      }
    }
    .input {
      width: 80%;
      height: auto;
      border-radius: 50px;
      padding: 5px;
      background: none;
      border: 2px solid #333366;
      margin: auto;
      margin-top: 5%;
      -webkit-text-fill-color: #009999;
      font-size: 17px;
      text-align: center;
    }
  }
  .footer {
    width: 250px;
    height: 64px;
    margin: 1% auto;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px;
    background-image: url('../../../assets/中秋0816/按钮 框.png');
    background-size: 100% 100%;
    background-position: center center;
    overflow: auto;
    .button {
      height: 100%;
      border-radius: 50px;
      background: none;
      font-size: 21px;
      border: none;
    }
  }
}
</style>
