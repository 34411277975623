<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-15 10:18:01
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-26 13:47:25
 * @FilePath: \mid-autumn\src\views\home\end\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <!-- 中部 -->
    <div class="main">
      <img src="../../../assets/中秋0816/组 2.png" alt="" />
      <div class="size">
        <p>
          您的手机号码已经录入系统,
          <br />
          数字藏品空投预计发放时间一周左右
          <br />
          请您注意查收短信
          <br />
          耐心等待
        </p>
      </div>
    </div>
    <!-- 尾部 -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.index {
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../../../assets/星空.jpg');
  background-size: 100% 100%;
  background-position: center center;
  .main {
    flex: 1;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    background-image: url('../../../assets/中秋0816/遮照.png');
    background-size: 100% 100%;
    background-position: center center;

    img {
      width: 20%;
      margin-left: 40%;
      margin-top: 40%;
    }
    .size {
      margin-top: 8%;
      p {
        font-size: 18px;
        text-align: center;
        line-height: 25px;
        span {
          display: inline-block;
        }
      }
    }

    .footer {
      width: 250px;
      height: 64px;
      margin: 1% auto;
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50px;
      background-image: url('../../../assets/中秋0816/按钮 框.png');
      background-size: 100% 100%;
      background-position: center center;
      overflow: auto;
      .button {
        height: 100%;
        border-radius: 50px;
        background: none;
        font-size: 21px;
        border: none;
      }
    }
  }
}
</style>
